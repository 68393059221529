import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <h1>About AI-Sidewalk</h1>
    <Img
        fluid={data.file.childImageSharp.fluid}
        style={{marginLeft:'auto', marginRight:'auto', marginBottom:'15px'}}
    />
    <p>
        AI-Sidewalk is a blog about the exciting and rapidly evolving world of Artificial Intelligence and Machine Learning, and will be covering a wide-range of content in these
        fields from the latest and greatest research, interesting and on-going projects in the field, to more theoretical explanations and breakdowns of key concepts and Algorithms.
        In order to make navigating the articles simpler, I will be tagging each of them with relevant topics. The 'Tags' page should provide easy access to all articles that fall
        into a certain category. Additionally I will be using the tags 'Beginner', 'Intermediate', and 'Advanced' to try and approximate what level of background information is needed
        to get the most out of each article. I will be posting any code related to the articles and on-going project in the public ai-sidewalk-code repo, feel free to fork and customize/add-on
        to any of the content in there. On the whole I am excited to start exploring deeper into the world of Intelligence and am very glad that you can join along on this journey
        down the ... AI-Sidewalk!
    </p>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "ai-sidewalk.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 300, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default AboutPage
